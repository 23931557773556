import React, { useState } from 'react';
import axios from 'axios';
import { useAlert } from 'react-alert';
import { useNavigate , Link } from 'react-router-dom'; // Correct usage of useNavigate
import { encryptToken } from '../utils/auth';
import './Login.css'; // Import CSS for styling

const Login = () => {
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const alert = useAlert();
  const navigate = useNavigate(); // Use useNavigate for navigation

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://prescription-backend-production.up.railway.app/api/auth/login', {
        userId,
        password,
      });

      if (response.status === 200) {
        const { token } = response.data;
        // Decode token to get role
        const payload = JSON.parse(atob(token.split('.')[1]));
        const encryptedToken = encryptToken(token);

        // Store the role and encrypted token in localStorage
        localStorage.setItem('role', payload.role); // Store the role
        console.log('Role stored:', payload.role); // Debugging role storage

        if (payload.role === 'doctor') {
          // Store the doctor token
          localStorage.setItem('doctorToken', encryptedToken);
          console.log('Doctor token stored:', encryptedToken); // Debugging token storage
          navigate('/doctor-dashboard'); // Redirect to doctor dashboard
        } else if (payload.role === 'patient') {
          // Store the patient token
          localStorage.setItem('patientToken', encryptedToken);
          console.log('Patient token stored:', encryptedToken); // Debugging token storage
          navigate('/patient-dashboard'); // Redirect to patient dashboard
        }

        alert.success('Login successful!');
      }
    } catch (error) {
      if (error.response) {
        alert.error(error.response.data.message || 'Login failed');
      } else {
        alert.error('An error occurred during login');
      }
      console.error('Login error:', error);
    }
  };

  return (
    <div className="login-container">
      <h2>Login</h2>
      <form onSubmit={handleLogin} className="login-form">
        <label>User ID:</label>
        <input
          type="text"
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
          required
        />

        <label>Password:</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />

        <button type="submit">Login</button>
      </form>
      {/* Add the "New Account? Signup" text with a link to the signup page */}
      <p className="signup-text">
        New Account? <Link to="/signup">Signup</Link>
      </p>
    </div>
  );
};

export default Login;
