import React from 'react';
import './Footer.css'; // Import CSS for styling the footer

const Footer = () => {
  return (
    <footer className="footer">
<p>
  Made with ❤️&nbsp;by&nbsp;
  <a href="https://github.com/anmolkys" target="_blank" rel="noopener noreferrer">@anmolkys</a> , 
  <a href="https://github.com/shivankk-pandeyy" target="_blank" rel="noopener noreferrer">@shivankkpandey</a> , 
  <a href="https://github.com/ameeshbhatia" target="_blank" rel="noopener noreferrer">@ameeshbhatia</a>
</p>

    </footer>
  );
};

export default Footer;
