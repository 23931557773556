import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { decryptToken } from '../utils/auth';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import { IoSync } from "react-icons/io5";
import { Line } from 'react-chartjs-2';
import './PatientDashboard.css';
import PrescriptionDetails from './PrescriptionDetails'; // Import PrescriptionDetails
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const PatientDashboard = () => {
  const [details, setDetails] = useState(null);
  const [secretCode, setSecretCode] = useState('');
  const [expiresAt, setExpiresAt] = useState('');
  const [countdown, setCountdown] = useState('');
  const [prescriptions, setPrescriptions] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('prescriptions'); // For tab switching
  const [selectedPrescription, setSelectedPrescription] = useState(null); // For "More" details popup
  const alert = useAlert();
  const navigate = useNavigate();

  useEffect(() => {
    const encryptedToken = localStorage.getItem('patientToken');
    const token = decryptToken(encryptedToken);

    if (!token) {
      alert.error('Please login');
      navigate('/login');
    } else {
      axios.get('https://prescription-backend-production.up.railway.app/api/patient/details', {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then(response => setDetails(response.data))
        .catch(error => alert.error(error.response?.data?.message || 'Failed to fetch details'));

      axios.post('https://prescription-backend-production.up.railway.app/api/secretCodes/generate', {}, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then(response => {
          setSecretCode(response.data.code);
          setExpiresAt(new Date(response.data.expiresAt));
        })
        .catch(error => alert.error(error.response?.data?.message || 'Failed to generate secret code'));
    }
  }, [alert, navigate]);

  useEffect(() => {
    if (expiresAt) {
      const interval = setInterval(() => {
        const timeDiff = expiresAt - new Date();
        if (timeDiff <= 0) {
          setCountdown('Expired');
          clearInterval(interval);
        } else {
          const minutes = Math.floor(timeDiff / 60000);
          const seconds = Math.floor((timeDiff % 60000) / 1000);
          setCountdown(`${minutes}m ${seconds}s`);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [expiresAt]);

  const regenerateSecretCode = async () => {
    setIsLoading(true);
    if ((expiresAt - new Date()) > 0) {
      alert.info('Code Not Expired');
      setIsLoading(false);
      return;
    }
    const encryptedToken = localStorage.getItem('patientToken');
    const token = decryptToken(encryptedToken);

    try {
      const response = await axios.post('https://prescription-backend-production.up.railway.app/api/secretCodes/generate', {}, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSecretCode(response.data.code);
      setExpiresAt(new Date(response.data.expiresAt));
      alert.success('Secret code regenerated successfully!');
    } catch (error) {
      alert.error(error.response?.data?.message || 'Failed to regenerate secret code');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPrescriptions = async () => {
    setIsLoading(true);
    const encryptedToken = localStorage.getItem('patientToken');
    const token = decryptToken(encryptedToken);

    try {
      const response = await axios.get('https://prescription-backend-production.up.railway.app/api/patient/prescriptions', {
        headers: { Authorization: `Bearer ${token}` },
      });

      setPrescriptions(response.data.prescriptions);

      const imagePromises = response.data.prescriptions.map(prescription =>
        fetchImageUrl(prescription.image, details.userId)
      );
      const imageUrls = await Promise.all(imagePromises);
      setImageUrls(imageUrls);
    } catch (error) {
      alert.error(error.response?.data?.message || 'Failed to fetch prescriptions');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchImageUrl = async (filename, patientId) => {
    const encryptedToken = localStorage.getItem('patientToken');
    const token = decryptToken(encryptedToken);

    try {
      const response = await axios.get(
        `https://prescription-backend-production.up.railway.app/api/prescriptions/image/${filename}`,
        {
          headers: { Authorization: `Bearer ${token}` },
          params: { patientId },
          responseType: 'blob',
        }
      );
      return URL.createObjectURL(response.data);
    } catch (error) {
      return null;
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('patientToken');
    alert.success('Logged out successfully');
    navigate('/login');
  };

  // Show prescription details as a popup
  const showPrescriptionDetails = (prescription, imageUrl) => {
    setSelectedPrescription({ prescription, imageUrl });
  };

  // Close the prescription details popup
  const closePrescriptionDetails = () => {
    setSelectedPrescription(null);
  };

  // Data for the charts
  const chartData = prescriptions.map(prescription => ({
    date: new Date(prescription.date).toLocaleDateString(),
    weight: prescription.weight,
    pulse: prescription.pulse,
    systolicBP: prescription.bloodPressure?.systolic,
    diastolicBP: prescription.bloodPressure?.diastolic,
    temperature: prescription.temperature,
  }));

  const weightChartData = {
    labels: chartData.map(prescription => prescription.date),
    datasets: [{
      label: 'Weight (kg)',
      data: chartData.map(prescription => prescription.weight),
      fill: false,
      backgroundColor: 'rgba(75, 192, 192, 0.6)',
      borderColor: 'rgba(75, 192, 192, 1)',
    }]
  };

  const pulseChartData = {
    labels: chartData.map(prescription => prescription.date),
    datasets: [{
      label: 'Pulse (bpm)',
      data: chartData.map(prescription => prescription.pulse),
      fill: false,
      backgroundColor: 'rgba(153, 102, 255, 0.6)',
      borderColor: 'rgba(153, 102, 255, 1)',
    }]
  };

  const bloodPressureChartData = {
    labels: chartData.map(prescription => prescription.date),
    datasets: [
      {
        label: 'Systolic BP (mm Hg)',
        data: chartData.map(prescription => prescription.systolicBP),
        fill: false,
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
        borderColor: 'rgba(255, 99, 132, 1)',
      },
      {
        label: 'Diastolic BP (mm Hg)',
        data: chartData.map(prescription => prescription.diastolicBP),
        fill: false,
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
        borderColor: 'rgba(54, 162, 235, 1)',
      }
    ]
  };

  const temperatureChartData = {
    labels: chartData.map(prescription => prescription.date),
    datasets: [{
      label: 'Temperature (°C)',
      data: chartData.map(prescription => prescription.temperature),
      fill: false,
      backgroundColor: 'rgba(255, 206, 86, 0.6)',
      borderColor: 'rgba(255, 206, 86, 1)',
    }]
  };

  return (
    <div className="patient-dashboard-container">
      <div className="tab-navigation">
        <button className={activeTab === 'prescriptions' ? 'active' : ''} onClick={() => setActiveTab('prescriptions')}>Prescriptions</button>
        <button className={activeTab === 'analysis' ? 'active' : ''} onClick={() => setActiveTab('analysis')}>Analysis</button>
      </div>

      {activeTab === 'prescriptions' && (
        <>
          <div className="details">
            <p><strong>Patient ID:</strong> {details?.userId}</p> {/* Add this line */}
          </div>
          <div className="secret-code">
            <p><strong>Secret Code:</strong> {secretCode}</p>
            <button onClick={regenerateSecretCode} className={`regenerate-button ${isLoading ? 'spinning' : ''}`} disabled={isLoading}>
              <IoSync />
            </button>
            <p><strong>Expires in:</strong> {countdown}</p>
          </div>

          <button onClick={fetchPrescriptions} className="view-past-button" disabled={isLoading}>View History</button>

          {prescriptions.length > 0 && (
            <div className="prescriptions-grid">
              <h3>Past Prescriptions</h3>
              <div className="prescription-grid-container">
                {prescriptions.map((prescription, index) => (
                  <div className="prescription-card" key={prescription._id}>
                    <p><strong>Date:</strong> {new Date(prescription.date).toLocaleDateString()}</p>
                    <p><strong>Text:</strong> {prescription.text}</p>
                    <p><strong>Diagnosis:</strong> {prescription.diagnosis}</p>
                    <p><strong>Doctor ID:</strong> {prescription.doctorId}</p>
                    {imageUrls[index] && (
                      <img src={imageUrls[index]} alt="Prescription" width="200" />
                    )}
                    <button onClick={() => showPrescriptionDetails(prescription, imageUrls[index])}>More</button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}

      {activeTab === 'analysis' && (
        <div className="charts-container">
          <h3>Health Analysis</h3>
          <button onClick={fetchPrescriptions} className="view-past-button" disabled={isLoading}>Load Charts</button>
          <div className="chart">
            <Line data={weightChartData} options={{ plugins: { title: { display: true, text: 'Weight Over Time' } } }} />
          </div>
          <div className="chart">
            <Line data={pulseChartData} options={{ plugins: { title: { display: true, text: 'Pulse Over Time' } } }} />
          </div>
          <div className="chart">
            <Line data={bloodPressureChartData} options={{ plugins: { title: { display: true, text: 'Blood Pressure Over Time' } } }} />
          </div>
          <div className="chart">
            <Line data={temperatureChartData} options={{ plugins: { title: { display: true, text: 'Temperature Over Time' } } }} />
          </div>
        </div>
      )}

      {selectedPrescription && (
        <div className="prescription-popup-overlay">
          <PrescriptionDetails
            prescription={selectedPrescription.prescription}
            imageUrl={selectedPrescription.imageUrl}
            onClose={closePrescriptionDetails} // Pass the close function
          />
        </div>
      )}

      <button onClick={handleLogout} className="logout-button" disabled={isLoading}>Logout</button>
    </div>
  );
};

export default PatientDashboard;
